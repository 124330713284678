<template>
  <div v-if="showNotification" class="fcs-notification-in-app location-section" data-qa="fcs-notification-in-app">
    <div class="header">
      <div class="close-btn" data-qa="fcs-notification-in-app-close">
        <fcs-icon @click="onCloseClick" name="icon-Close" fill-color="#18191e" aria-label="close-notification-button"></fcs-icon>
      </div>
      <div @click="onLinkClick" class="notification-link" aria-label="notification-link">
        <div v-if="icon" class="notification-icon" data-qa="fcs-notification-in-app-icon">
          <fcs-icon :name="icon" :fill-color="iconFillColor"></fcs-icon>
        </div>
        <div class="title-description-notification">
          <div class="title" data-qa="fcs-notification-in-app-title">
            {{ title }}
          </div>
          <div class="description" data-qa="fcs-notification-in-app-description">
            <a v-if="this.$store.state.latitude != 0 && this.$store.state.longitude != 0" :href="`https://www.google.com/maps/search/?api=1&query=${this.$store.state.latitude},${this.$store.state.longitude}`" title="Click to view location" target="_blank" class="hyper-link" >
              {{ description }}
            </a>
            <label v-if="this.$store.state.latitude == 0 && this.$store.state.longitude == 0" >
              {{ $t('vehicleSecurity.descriptions.latLongError') }}
            </label>
            <!-- <a :href="`https://www.google.com/maps/search/?api=1&query=42.300852,-83.231761`" title="Click to view location" target="_blank" class="hyper-link" >
              Latitude: 42.300852, Longitude: -83.231761
            </a> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FcsBroncoNotificationInApp',
  components: {
  },
  data () {
    return {
      showNotification: true
    };
  },
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: false,
      default: ''
    },
    iconFillColor: {
      type: String,
      required: false
    },
    onLinkClick: {
      type: Function,
      required: true
    },
    onCloseClick: {
      type: Function,
      required: true
    }
  },
  methods: {
  },
  computed: {
    contentSize () {
      return 11;
    }
  }

};
</script>

<style lang="scss" scoped>
.location-section{
  width: auto;
  min-width: 350px !important;
  max-width: 450px !important;
}
.hyper-link{
  text-decoration: underline !important;
}
</style>
